import React, { useEffect, useState, useRef } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import ProjectsNav from './ProjectsNav';

const Projects = ({ projects, selectedProjectId, onSelectProject, lang }) => {
    const [currentProjectId, setCurrentProjectId] = useState(selectedProjectId);
    const refs = useRef({});

    useEffect(() => {
        if (selectedProjectId) {
            setCurrentProjectId(null);  // Trigger exit animation first
            setTimeout(() => {
                setCurrentProjectId(selectedProjectId);  // Set new project after exit animation
            }, 200);  // Match the timeout duration with your exit animation duration
        }
    }, [selectedProjectId]);

    const selectedProject = projects.find(project => project.id === currentProjectId);

    const projectSectionTitle = (lang === 'fr') ? 'Projets' : 'Projects';

    return (
        <section className="projects mt-24 mb-32">
            <div className="container mr-auto ml-auto px-8">
                <div className="text-center">
                    <h2 className="text-[2.5rem] lg:text-[3rem] leading-none font-semibold mb-12 text-[#484848] dark:text-white section--title">{projectSectionTitle}</h2>
                </div>
                <ProjectsNav projects={projects} onSelectProject={onSelectProject} selectedProjectId={currentProjectId} lang={lang} />
                <TransitionGroup component={null}>
                    {selectedProject && (
                        <CSSTransition key={selectedProject.id} timeout={200} classNames="fade" nodeRef={refs.current[selectedProject.id] = React.createRef()}>
                            <div ref={refs.current[selectedProject.id]} className="flex justify-between single-project flex-wrap">
                                <div className="w-full lg:w-1/2 pr-4 mb-12">
                                    <h3 className="text-[1.5rem] font-semibold mt-2 mb-4 text-primary dark:text-white">{(lang === 'fr'
                                        ? selectedProject.title
                                        : selectedProject.fields.title_en
                                    )}</h3>
                                    {lang === 'fr' ? (
                                        <div className="project-content text-primary dark:text-white dark:opacity-70" dangerouslySetInnerHTML={{ __html: selectedProject.content }}></div>
                                        ) : (
                                        <div className="project-content text-primary dark:text-white dark:opacity-70" dangerouslySetInnerHTML={{ __html: selectedProject.fields.english_description }}></div>
                                    )}
                                    {selectedProject.fields.skills_tags && (
                                        <ul className="flex flex-wrap my-6">
                                            {selectedProject.fields.skills_tags.map((tag, index) => (
                                                <li key={index} className="bg-[#F7F4F4] text-primary text-sm font-semibold py-2 px-5 rounded mr-2 mb-2 dark:bg-transparent dark:border-1 dark:border-white dark:text-white dark:opacity-80">{tag.tag}</li>
                                            ))}
                                        </ul>
                                    )}
                                    {((selectedProject.fields.lien_site && selectedProject.fields.lien_site_en && lang) &&
                                        <p><a href={(lang === 'fr' ? selectedProject.fields.lien_site.url : selectedProject.fields.lien_site_en.url)}
                                            className="transition ease-in-out duration-[200ms] inline-block bg-peach py-2 px-5 text-primary font-medium rounded hover:underline dark:bg-transparent dark:border-2 dark:border-[#b768f7] dark:text-[#b768f7] dark:hover:shadow-[3px_3px_0_0_rgba(183,104,247,1)] sd-translate-3" target="_blank" rel="noreferrer">
                                            {(lang === 'fr' ? selectedProject.fields.lien_site.title : selectedProject.fields.lien_site_en.title)}
                                        </a></p>
                                    )}
                                </div>
                                <div className="w-full lg:w-5/12 lg:text-right">
                                    <svg width="554" viewBox="0 0 554 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="inline-block -mb-[15px] lg:-mb-[10px] relative z-10 max-w-full">
                                        <path d="M6.752 0.29895H547.06C550.789 0.29895 553.812 3.32195 553.812 7.05095V19.621H0V7.05095C0 3.32195 3.023 0.29895 6.752 0.29895Z" fill="#E4EEF4"/>
                                        <path d="M15.322 9.95997C15.322 7.82997 17.049 6.10297 19.179 6.10297C21.309 6.10297 23.036 7.82997 23.036 9.95997C23.036 12.09 21.309 13.817 19.179 13.817C17.049 13.817 15.322 12.09 15.322 9.95997Z" fill="#E73555"/>
                                        <path d="M32.445 9.95997C32.445 7.82997 34.172 6.10297 36.302 6.10297C38.432 6.10297 40.159 7.82997 40.159 9.95997C40.159 12.09 38.432 13.817 36.302 13.817C34.172 13.817 32.445 12.09 32.445 9.95997Z" fill="#FECB2F"/>
                                        <path d="M49.568 9.95997C49.568 7.82997 51.295 6.10297 53.425 6.10297C55.555 6.10297 57.282 7.82997 57.282 9.95997C57.282 12.09 55.555 13.817 53.425 13.817C51.295 13.817 49.568 12.09 49.568 9.95997Z" fill="#49AE48"/>
                                    </svg>
                                    {selectedProject.fields.screenshot && (
                                        <img
                                            src={selectedProject.fields.screenshot}
                                            alt={selectedProject.title}
                                            className="inline-block rounded-br-[10px] rounded-bl-[10px]"
                                        />
                                    )}
                                </div>
                            </div>
                        </CSSTransition>
                    )}
                </TransitionGroup>
            </div>
        </section>
    );
};

export default Projects;
