import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';

const ContactForm = ({lang}) => {
    const submitText = (lang === 'fr') ? 'Envoyer' : 'Submit';
    const submittingText = (lang === 'fr') ? 'Envoyer...' : 'Submitting...';
    const [formData, setFormData] = useState({
        name: '',
        contact: '',
        message: '',
    });

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [captchaToken, setCaptchaToken] = useState('');
    const [responseMessage, setResponseMessage] = useState('');

    const handleCaptcha = (token) => {
        setCaptchaToken(token);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setResponseMessage('');

        if (!captchaToken) {
            setResponseMessage('Please complete the captcha.');
            setIsSubmitting(false);
            return;
        }

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_WORDPRESS_URL}/wp-json/wp/v2/contact-form`,
                { ...formData, captchaToken }
              );

            if (response.status === 200) {
                setResponseMessage('Message sent successfully!');
            } else {
                setResponseMessage('Unexpected response from server.');
            }
        } catch (error) {
            setResponseMessage('An error occurred. Please try again.');
            console.error('Error posting form data:', error);
        } finally {
            setIsSubmitting(false);
            setFormData(() => ({
                name: '',
                contact: '',
                message: '',
            }));
        }
    };


    return (
        <>
            <form onSubmit={handleSubmit} className="mt-12 xl:mt-0">
                <div className="input-group mb-5">
                    <label htmlFor="name" className="block font-medium text-white mb-2">{(lang === 'fr' ? 'Nom : ' : 'Name:')}</label>
                    <input 
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                        autoComplete="off"
                        className="w-full px-4 py-2 rounded border border-gray-300 focus:outline-none focus:border-primary dark:bg-transparent dark:border-[#b768f7] dark:text-[#b768f7] dark:focus:border-[#b768f7]"
                    />
                </div>
                <div className="input-group mb-5">
                    <label htmlFor="contact" className="block font-medium text-white mb-2">{(lang === 'fr' ? 'Courriel : ' : 'Email:')}</label>
                    <input 
                        type="email"
                        id="contact"
                        name="contact"
                        value={formData.contact}
                        onChange={handleChange}
                        required
                        autoComplete="off"
                        className="w-full px-4 py-2 rounded border border-gray-300 focus:outline-none focus:border-primary dark:bg-transparent dark:border-[#b768f7] dark:text-[#b768f7] dark:focus:border-[#b768f7]" 
                    />
                </div>
                <div className="input-group mb-5">
                    <label htmlFor="message" className="block font-medium text-white mb-2">{(lang === 'fr' ? 'Message : ' : 'Message:')}</label>
                    <textarea
                        id="message"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        required
                        className="w-full h-[140px] px-4 py-2 rounded border border-gray-300 focus:outline-none focus:border-primary dark:bg-transparent dark:border-[#b768f7] dark:text-[#b768f7] dark:focus:border-[#b768f7]"
                    />
                </div>
                <ReCAPTCHA
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                    theme="dark"
                    onChange={handleCaptcha}
                    className="g-recaptcha mb-5"
                />
                <div className="input-group mb-5">
                    <button
                        type="submit"
                        disabled={isSubmitting}
                        className="transition ease-in-out duration-[200ms] inline-block bg-dark py-2 px-5 text-white font-medium rounded hover:underline dark:bg-transparent dark:border-2 dark:border-[#b768f7] dark:text-[#b768f7]">
                        {isSubmitting ? submittingText : submitText}        
                    </button>
                </div>
                {responseMessage && <p className="mt-2 text-sm text-white">{responseMessage}</p>}
            </form>
        </>
    );
};

export default ContactForm;