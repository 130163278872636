import { useEffect } from 'react';

const useIntersectionObserver = (ref, className, threshold = 0.1, delay = 400) => {
    useEffect(() => {
        const target = ref.current;
        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    document.querySelectorAll(className).forEach((el, index) => {
                        setTimeout(() => {
                            el.classList.add('animate');
                        }, index * delay);
                    });
                    observer.disconnect();
                }
            });
        }, { threshold });

        if (target) {
            observer.observe(target);
        }

        return () => {
            if (target) {
                observer.unobserve(target);
            }
        };
    }, [ref, className, threshold, delay]);
};

export default useIntersectionObserver;