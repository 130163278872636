import React from 'react';
import MainNav from './MainNav';

export default function PageHeader({lang, sectionRefs}) {

    const pageName = (lang === 'fr') ? 'Politique de confidentialité' : 'Privacy Policy';

    return (
        <header className="header--banner pt-6 pb-6">
            <div className="container mr-auto ml-auto">
                <div className="flex flex-wrap justify-end items-center">
                    <div className="w-full lg:w-3/4">
                        <MainNav sectionRefs={sectionRefs} content="page" />
                    </div>
                </div>
                <div className="flex justify-center lg:justify-start items-center h-40v">
                    <div className="w-[90%] lg:w-[50%]">
                        <h2 className="text-[2rem] sm:text-[2rem] lg:text-[3rem] font-semibold leading-none mb-2">{pageName}</h2>                        
                    </div>
                </div>
            </div>
        </header>
    );

}