import React, { useState, forwardRef } from 'react';
import Projects from './Projects';

const ProjectsContainer = forwardRef(({ projects, lang }, ref) => {
    const [selectedProjectId, setSelectedProjectId] = useState(null);

    if (!selectedProjectId && projects.length > 0) {
        setSelectedProjectId(projects[0].id);
    }

    const handleSelectProject = (projectId) => {
        setSelectedProjectId(projectId);
    };

    return (
        <div ref={ref}>
            <Projects projects={projects} selectedProjectId={selectedProjectId} onSelectProject={handleSelectProject} lang={lang} />
        </div>
    );
});

export default ProjectsContainer;
