import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PageHeader from './PageHeader';
import PageContent from './PageContent';
import PortfolioFooter from './Footer';

export default function Homepage({lang}) {

    const [optionPage, setOptionPage] = useState([]);
    const [optionLoaded, setOptionLoaded] = useState(false);

    const darkMode = localStorage.getItem("darkmode");

    if(darkMode === "true") {
      document.documentElement.classList.add('dark');
    }

    useEffect(() => {
      const fetchOptionPage = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_WORDPRESS_URL}/wp-json/wp/child-theme-2024/v1/acf-options/options`);
          setOptionPage(response.data);
          setOptionLoaded(true);
        } catch (error) {
          console.error('Error fetching options:', error);
          setOptionLoaded(false);
        }
      };
  
      fetchOptionPage();
    }, []);



    if (!optionLoaded) {
      return (
        <div className="loader--banner">
          <div className="container mr-auto ml-auto">
            <div className="flex justify-center items-center h-100v">
              <div className="loader"></div>
            </div>
          </div>
        </div>
      );
    }

    return (
        <>
          {(optionPage &&
            <PageHeader lang={lang} />
          )}
          <PageContent optionContent={optionPage}  lang={lang} />
          <PortfolioFooter lang={lang} />
        </>
    );

}