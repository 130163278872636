import React from 'react';

export default function PageContent({optionContent, lang}) {

    const content = (lang === 'fr') ? optionContent.privacy_fr : optionContent.privacy_en;

    return (
       <section>
            <div className="container mr-auto ml-auto">
                {(content &&
                    <div className="py-8 page-content" dangerouslySetInnerHTML={{__html: content}}></div>
                )}
            </div>
       </section>
    );

}