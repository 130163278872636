import React from 'react';
import MainNav from './MainNav';

export default function Header({lang, sectionRefs}) {

    const name = (lang === 'fr') ? 'Stéphanie Ducasse' : 'Stephanie Ducasse';

    return (
        <header className="header--banner pt-6 pb-6">
            <div className="container mr-auto ml-auto">
                <div className="flex flex-wrap justify-end items-center">
                    <div className="w-full lg:w-3/4">
                        <MainNav sectionRefs={sectionRefs} content="home" />
                    </div>
                </div>
                <div className="flex justify-center lg:justify-start items-center h-60v md:h-80v">
                    <div className="w-[90%] lg:w-[50%]">
                        <h2 className="text-[2rem] sm:text-[2rem] lg:text-[3rem] font-semibold leading-none mb-2">{name}</h2>

                        {(lang === 'fr') ? (
                            <p className="text-[2.2rem] sm:text-[3rem] lg:text-[3.2rem] font-semibold leading-[1.25]"><span className="opacity-60">Conception de sites web <em>Responsive</em> et de</span> <span className="highlighted-text">solutions</span> <span className="highlighted-text">personnalisées</span><span className="opacity-60">, adaptées à votre entreprise.</span></p>
                        ) : (
                            <p className="text-[2.2rem] sm:text-[3rem] lg:text-[3.5rem] font-semibold leading-[1.25]"> <span className="opacity-60">Crafting responsive websites with</span> <span className="highlighted-text">custom</span> <span className="highlighted-text">solutions</span> <span className="opacity-60">tailored to your business.</span></p>
                        )}
                        
                    </div>
                </div>
            </div>
        </header>
    );

}