export default function PortfolioFooter({lang}) {

    const name = (lang === 'fr') ? 'Stéphanie Ducasse' : 'Stephanie Ducasse';
    const slogan = (lang === 'fr') ? 'Développeuse web à Gatineau, QC' : 'Web Developer in Gatineau, QC';
    const copy = (lang === 'fr') ? 'Tous droits réservés.' : 'All Rights Reserved.';
    const today = new Date();
    const year = today.getFullYear();

    return (
        <footer className="bg-darker text-white py-12 dark:bg-[#0a192f]">
            <div className="container mr-auto ml-auto">
                <div className="flex flex-wrap justify-center text-center lg:text-left lg:justify-between items-end">
                    <div className="w-full lg:w-1/2">
                        <p className="text-[1.1rem] font-semibold">{name}</p>
                        <p>{slogan}</p>
                    </div>
                    <div className="w-full lg:w-1/2">
                        <p className="text-[0.9rem] lg:text-right">&copy; {year}, {name}. {copy}</p>
                    </div>
                </div>
            </div>
        </footer>
    );

}