import React, { useEffect } from 'react'
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import Homepage from './components/Homepage';
import PrivacyPolicy from './components/PrivacyPolicy';
import { LanguageProvider, useLanguage } from './components/LanguageContext';

function AppRoutes() {
  const location = useLocation();
  const { lang, changeLang } = useLanguage();

  useEffect(() => {
    if (lang === 'en' && !location.pathname.startsWith('/en')) {
      changeLang('fr')
    } 
    else if (lang === 'fr' && location.pathname.startsWith('/en')) {
      changeLang('en')
    }
    document.documentElement.lang = lang;
  }, [location.pathname, lang, changeLang]);


  return (
    <Routes>
      <Route path="/" element={<div className="App"><Homepage lang={lang} /></div>} />
      <Route path="/en" element={<div className="App"><Homepage lang={lang} /></div>} />
      <Route path="/politique-confidentialite" element={<PrivacyPolicy lang={lang} />} />
      <Route path="/en/politique-confidentialite" element={<PrivacyPolicy lang={lang} />} />
    </Routes>
  );
}

function App() {
  return (
    <Router>
      <LanguageProvider>
        <AppRoutes />
      </LanguageProvider>
    </Router>
  );
}

export default App;