import React, { useEffect, useState, useRef }  from 'react';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from './LanguageContext';

const MainNav = ({sectionRefs, content}) => {
    const navRef = useRef(null);
    const menuRef = useRef(null);
    const menuToggleRef = useRef(null);
    const { lang, changeLang } = useLanguage();
    const [isDark, setIsDark] = useState(localStorage.getItem("darkmode"));
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const navigate = useNavigate();

    const handleLanguageChange = (newLang) => {
        changeLang(newLang);
        const path = window.location.pathname.replace(/^\/en/, ''); // Remove /en if present
        if (newLang === 'en') {
            navigate(`/en${path}`);
        } else {
            navigate(path);
        }
    };

    const handleMenuToggle = (e) => {
        e.preventDefault();
        setIsMenuOpen(prevState => !prevState);
    };
      
    const scrollToSection = (ref) => {
        ref.current.scrollIntoView({ behavior: 'smooth' });
        setIsMenuOpen(prevState => !prevState);
    };

    useEffect(() => {
        const menuToggleElement = menuToggleRef.current;
        menuToggleElement.addEventListener('click', handleMenuToggle);
      
        return () => {
          menuToggleElement.removeEventListener('click', handleMenuToggle);
        };
    }, [isMenuOpen]);


    const handleDarkModeToggle = () => {
        if (!document.documentElement.classList.contains('dark') ) {
            document.documentElement.classList.add('dark');
            localStorage.setItem('darkmode', true);
            setIsDark(true);
        } else {
            document.documentElement.classList.remove('dark');
            localStorage.setItem('darkmode', false);
            setIsDark(false);
        }
    };    


    return (
        <>
            {/* Desktop Nav */}
            <nav className="main-nav hidden lg:block">
                <ul className="flex items-center justify-end">
                    {(content === 'home') ? (
                        <>
                            <li className="menu-item pl-5 pr-5"><button onClick={(e) => {
                                e.preventDefault();
                                scrollToSection(sectionRefs.services)
                            }}>Services</button></li>
                            <li className="menu-item pl-5 pr-5"><button onClick={(e) => {
                                e.preventDefault();
                                scrollToSection(sectionRefs.about)
                            }}>{(lang === 'fr') ? 'À propos' : 'About'}</button></li>
                            <li className="menu-item pl-5 pr-5"><button onClick={(e) => {
                                e.preventDefault();
                                scrollToSection(sectionRefs.projects)
                            }}>{(lang === 'fr') ? 'Projets' : 'Projects'}</button></li>
                            <li className="menu-item pl-5 pr-5"><button onClick={(e) => {
                                e.preventDefault();
                                scrollToSection(sectionRefs.contact)
                            }}>Contact</button></li>
                        </>
                    ) : (
                        <>
                            <li className="menu-item pl-5 pr-5"><a href={(lang === 'fr') ? '/' : '/en'}>{(lang === 'fr') ? 'Accueil' : 'Home'}</a></li>
                        </>
                    )}
                    <li className="menu-item pl-5 pr-5">
                        {(lang === 'fr') ? (
                            <button onClick={() => handleLanguageChange('en')}>English</button>
                        ) : (
                            <button onClick={() => handleLanguageChange('fr')}>Français</button>
                        )}
                    </li>
                    <li className="pl-5">
                        <label className="flex items-end cursor-pointer">
                            <input type="checkbox" value="" className="sr-only peer" {...((isDark === 'true') ? { checked: true } : {})} onChange={(e) => {
                                handleDarkModeToggle();
                            }}/>
                            <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:violet2 dark:peer-focus:violet rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-violet2"></div>
                            <span className="ms-3 text-[0.9rem] text-white">Dark Mode</span>
                        </label>
                    </li>
                </ul>
            </nav>

            {/* Mobile Nav */}
            <nav id="nav" ref={navRef} className={`lg:hidden nav ${isMenuOpen ? 'nav--open' : ''}`}>
                <button className="nav__toggle" ref={menuToggleRef} aria-expanded={isMenuOpen}>
                    <svg className="menuicon" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
                        <title>Toggle Menu</title>
                        <g>
                            <line className="menuicon__bar" x1="13" y1="16.5" x2="37" y2="16.5"/>
                            <line className="menuicon__bar" x1="13" y1="24.5" x2="37" y2="24.5"/>
                            <line className="menuicon__bar" x1="13" y1="24.5" x2="37" y2="24.5"/>
                            <line className="menuicon__bar" x1="13" y1="32.5" x2="37" y2="32.5"/>
                        </g>
                    </svg>
                </button>
                <label className="dark-mode-mobile flex items-center cursor-pointer">
                    <input type="checkbox" value="" className="sr-only peer" {...((isDark === 'true') ? { checked: true } : {})} onChange={(e) => {
                        handleDarkModeToggle();
                    }}/>
                    <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:violet2 dark:peer-focus:violet rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-violet2"></div>
                    <span className="ms-3 text-[0.9rem] text-white">Dark Mode</span>
                </label>
                <div className="splash"></div>
                <div id="menu" ref={menuRef} className={`${isMenuOpen ? 'h-[100%]' : 'h-[0px]'}`}>
                    <ul className="nav__menu text-center text-[1.5rem] font-medium text-white" id="menu" tabIndex="-1" aria-label="main navigation">
                        {(content === 'home') ? (
                            <>
                                <li className="px-5 py-3 nav__item"><button onClick={(e) => {
                                    e.preventDefault();
                                    scrollToSection(sectionRefs.services)
                                }}>Services</button></li>
                                <li className="px-5 py-3 nav__item"><button onClick={(e) => {
                                    e.preventDefault();
                                    scrollToSection(sectionRefs.about)
                                }}>{(lang === 'fr') ? 'À propos' : 'About'}</button></li>
                                <li className="px-5 py-3 nav__item"><button onClick={(e) => {
                                    e.preventDefault();
                                    scrollToSection(sectionRefs.projects)
                                }}>{(lang === 'fr') ? 'Projets' : 'Projects'}</button></li>
                                <li className="px-5 py-3 nav__item"><button onClick={(e) => {
                                    e.preventDefault();
                                    scrollToSection(sectionRefs.contact)
                                }}>Contact</button></li>
                            </>
                        ) : (
                            <>
                                <li className="px-5 py-3 nav__item"><a href={(lang === 'fr') ? '/' : '/en'}>{(lang === 'fr') ? 'Accueil' : 'Home'}</a></li>
                            </>
                        )}
                        <li className="px-5 py-3 nav__item">
                            {(lang === 'fr') ? (
                                <button onClick={() => handleLanguageChange('en')}>English</button>
                            ) : (
                                <button onClick={() => handleLanguageChange('fr')}>Français</button>
                            )}
                        </li>
                        
                    </ul>
                </div>
            </nav>
        </>
    );
};

export default MainNav;
