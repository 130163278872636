import React, { forwardRef } from 'react';
import useIntersectionObserver from './Observer';

const About = forwardRef(({ aboutContent }, ref) => {
    const AboutMeContent = (aboutContent) ? aboutContent.about_me : '';
    const AboutMeImg = (aboutContent) ? aboutContent.about_me_image : '';
    const AboutMeButton = (aboutContent) ? aboutContent.about_me_button : '';
    const sectionRef = ref;
    useIntersectionObserver(sectionRef, '.animate-item-about');

    return (
        <section ref={ref} className="about bg-[#fff8ff] dark:bg-[#1e2c42]">
            <div className="container mr-auto ml-auto">
                <div className="flex flex-wrap items-center py-12 lg:py-32">
                    <div  className="w-full lg:w-1/2 pl-2 pr-2">
                        {(AboutMeContent &&
                            <div className="about-me-content transition opacity-0 translate-y-24 ease-in-out duration-[800ms] animate-item-about text-[#484848] px-8 lg:px-0 lg:w-[80%]" dangerouslySetInnerHTML={{__html: AboutMeContent}}></div>
                        )}
                        {(AboutMeButton &&
                            <p className="text-center lg:text-left transition opacity-0 translate-y-24 ease-in-out duration-[800ms] animate-item-about">
                                <a href={AboutMeButton.url} target="_blank" rel='noopener noreferrer' className="transition ease-in-out duration-[200ms] inline-block bg-peach py-2 px-5 text-primary font-medium rounded hover:underline dark:bg-transparent dark:border-2 dark:border-[#b768f7] dark:text-[#b768f7] dark:hover:shadow-[3px_3px_0_0_rgba(183,104,247,1)] sd-translate-3">
                                    {AboutMeButton.title}
                                </a>
                            </p>
                        )}
                    </div>
                    {(AboutMeImg &&
                        <div className="lg:w-[40%] pl-2 pr-2 text-center"></div>
                    )}
                </div>
            </div>
        </section>
    );
});

export default About;