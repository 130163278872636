import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import Header from './HomeHeader';
import About from './About';
import ProjectsContainer from './ProjectsContainer';
import Services from './Services';
import CTA from './CallToAction';
import PortfolioFooter from './Footer';

export default function Homepage({lang}) {

    const [home, setHome] = useState([]);
    const [loaded, setLoaded] = useState(false);

    const [optionPage, setOptionPage] = useState([]);
    const [optionLoaded, setOptionLoaded] = useState(false);

    const [projects, setProjects] = useState([]);
    const [projectLoaded, setProjectLoaded] = useState(false);

    const darkMode = localStorage.getItem("darkmode");

    if(darkMode === "true") {
      document.documentElement.classList.add('dark');
    }

    const sectionRefs = {
      projects: useRef(null),
      services: useRef(null),
      about: useRef(null),
      contact: useRef(null),
    };

    useEffect(() => {
      const fetchHomepageContent = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_WORDPRESS_URL}/wp-json/wp/child-theme-2024/v1/page/12`);
          setHome(response.data);
          setLoaded(true);
        } catch (error) {
          console.error('Error fetching options:', error);
          setLoaded(false);
        }
      };
  
      fetchHomepageContent();
    }, []);

    useEffect(() => {
      const fetchOptionPage = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_WORDPRESS_URL}/wp-json/wp/child-theme-2024/v1/acf-options/options`);
          setOptionPage(response.data);
          setOptionLoaded(true);
        } catch (error) {
          console.error('Error fetching options:', error);
          setOptionLoaded(false);
        }
      };
  
      fetchOptionPage();
    }, []);

    useEffect(() => {
      const fetchProjectsContent = async () => {
          try {
              const response = await axios.get(`${process.env.REACT_APP_WORDPRESS_URL}/wp-json/wp/v2/projets/`);
              setProjects(response.data);
              setProjectLoaded(true);
          } catch (error) {
              console.error('Error fetching options:', error);
              setProjectLoaded(false);
          }
      };

      fetchProjectsContent();
      // eslint-disable-next-line
    }, []);

    if (!loaded || !optionLoaded || !projectLoaded) {
      return (
        <div className="loader--banner">
          <div className="container mr-auto ml-auto">
            <div className="flex justify-center items-center h-100v">
              <div className="loader"></div>
            </div>
          </div>
        </div>
      );
    }

    return (
        <>
          {(optionPage &&
            <Header sectionRefs={sectionRefs} lang={lang} />
          )}
          {(home &&
            <Services servicesContent={(lang === 'fr') ? home.services_section : home.services_section_en} ref={sectionRefs.services} lang={lang} />
          )}
          {(home &&
            <About aboutContent={(lang === 'fr') ? home.about_section : home.about_section_en} ref={sectionRefs.about} />
          )}
          {(projects &&
            <ProjectsContainer projects={projects} ref={sectionRefs.projects} lang={lang} />
          )}
          {(optionPage &&
            <CTA optionContent={optionPage} ref={sectionRefs.contact} lang={lang} />
          )}
          <PortfolioFooter lang={lang} />
        </>
    );

}