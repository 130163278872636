import React from 'react';

const ProjectsNav = ({ projects, onSelectProject, selectedProjectId, lang }) => {
    return (
        <nav className="flex flex-wrap justify-center mb-24">
            {projects.map((project) => (
                (project.id === selectedProjectId ? (
                    <a href={`#${project.id}`} key={project.id}
                        onClick={(e) => {
                            e.preventDefault();
                            onSelectProject(project.id);
                        }}
                        className="inline-block bg-violet py-2 px-5 mx-2 mb-3 text-primary font-medium rounded dark:bg-[#b768f7] dark:border-2 dark:border-[#b768f7] dark:text-white">
                            {(lang === 'fr' ? project.title  : project.fields.title_en )}
                    </a>
                ) : (
                    <a href={`#${project.id}`} key={project.id}
                        onClick={(e) => {
                            e.preventDefault();
                            onSelectProject(project.id);
                        }}
                        className="transition ease-in-out duration-[200ms] mb-3 inline-block bg-peach py-2 px-5 mx-2 text-primary font-medium rounded hover:underline dark:bg-transparent dark:border-2 dark:border-[#b768f7] dark:text-[#b768f7] dark:hover:shadow-[3px_3px_0_0_rgba(183,104,247,1)] sd-translate-3">
                            {(lang === 'fr' ? project.title  : project.fields.title_en )}
                    </a>
                ))
            ))}
        </nav>
    );
};

export default ProjectsNav;
