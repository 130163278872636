import React, { forwardRef } from 'react';
import useIntersectionObserver from './Observer';
import ContactForm from './ContactForm';

const CTA = forwardRef(({ optionContent, lang }, ref) => {
    const contactInfo = optionContent.contact_infos;
    const sectionRef = ref;
    useIntersectionObserver(sectionRef, '.animate-item-cta');

    return (
        <section ref={ref} className="call-to-action py-20 bg-darker dark:bg-[#0a192f]">
            <div className="container mr-auto ml-auto dark:bg-[#1e2c42] dark:pt-24 dark:pb-24 dark:rounded-[10px]">
                <div className="flex flex-wrap">
                    <div className="w-full text-center lg:w-1/2 lg:text-left text-animation">
                        <div className="px-8 transition opacity-0 translate-y-24 ease-in-out duration-[800ms] animate-item-cta">
                            {((optionContent.cta_title && optionContent.cta_title_en && lang) &&
                                <h2 className="text-[3rem] font-semibold mb-4 text-white">{(lang === 'fr' ? optionContent.cta_title  : optionContent.cta_title_en )}</h2>
                            )}
                            {((optionContent.cta_description && optionContent.cta_description_en && lang) &&
                                <p className="text-[1.2rem] font-medium text-white mb-8">{(lang === 'fr' ? optionContent.cta_description  : optionContent.cta_description_en )}</p>
                            )}
                            <div className="overflow-hidden opacity-0 translate-y-24 w-full h-full delay-[500ms] transition animate-item-cta ease-in-out duration-[800ms] animate">
                                <div className="flex flex-wrap items-center">
                                    {contactInfo.map((item, index) => {
                                        return (
                                            <a href={item.link.url} className="pr-3 flex items-center w-3/4 mb-4 text-white contact-btn" key={index} target="_blank" rel='noopener noreferrer'>
                                                <i className={`${item.icon_font} text-white me-2`}></i> <span>{item.link.title}</span>
                                            </a>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="overflow-hidden px-8 opacity-0 translate-y-24 w-full xl:w-1/2 h-full delay-[500ms] transition animate-item-cta ease-in-out duration-[800ms] animate">
                        <ContactForm lang={lang} />
                    </div>
                </div>
            </div>
        </section>
    );
});

export default CTA;