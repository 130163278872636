import React, { forwardRef } from 'react';
import useIntersectionObserver from './Observer';

const Services = forwardRef(({ servicesContent }, ref) => {
    const services = (servicesContent) ? servicesContent.services : '';
    const sectionRef = ref;
    useIntersectionObserver(sectionRef, '.animate-item-service');

    return (
        <section ref={ref} className="about mr-auto ml-auto dark:text-white pt-28 pb-24 xl:pb-20">
            <div className="container mr-auto ml-auto">
                <div className="flex flex-wrap">
                    <div className="transition opacity-0 translate-y-24 ease-in-out duration-[800ms] animate-item-service w-full mb-12 lg:w-1/4">
                        <h2 className="text-center text-[2.5rem] lg:text-left lg:text-[3rem] leading-none font-semibold mb-12 text-[#484848] dark:text-white section--title">Services</h2>
                    </div>
                    <div className="transition opacity-0 translate-y-24 ease-in-out duration-[800ms] animate-item-service w-full lg:w-3/4">      
                        <div className="flex flex-wrap -mx-8">
                            {services.map((service, index) => {
                                return (
                                    <div className="w-full mb-12 md:w-1/2" key={index}>
                                        <div className="pr-12 pl-12">
                                            <i className={`leading-none mb-4 text-[#e3a2e7] dark:text-[#b768f7] ${service.icon}`}></i>
                                            <h3 className="text-[1.5rem] lg:text-[1.8rem] font-medium mb-4 text-[#484848] dark:text-white">{service.title}</h3>
                                            {(service.description &&
                                                <div className="leading-[1.8] text-[#484848] dark:text-white" dangerouslySetInnerHTML={{__html: service.description}}></div>
                                            )}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>   
                </div>
            </div>
        </section>
    );
});

export default Services;